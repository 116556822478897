import React, { useState, useEffect, useRef, useCallback } from "react";
import { Fade, Fab } from "@RHCommerceDev/utils/material-ui-core";
import RHArrowIcon from "@RHCommerceDev/icon-arrow";

const isWindowDefined = typeof window !== "undefined";

const useScrollVisibility = () => {
  const [isVisible, setIsVisible] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleScroll = useCallback(() => {
    if (!isWindowDefined) return;

    const docHeight = document?.body?.scrollHeight;
    const shouldBeVisible =
      window?.scrollY > window?.innerHeight &&
      docHeight - window?.scrollY - window?.innerHeight > 300;

    setIsVisible(shouldBeVisible);

    if (buttonRef?.current) {
      const position =
        (buttonRef?.current?.parentElement?.getBoundingClientRect()?.bottom ??
          0) < window?.innerHeight
          ? "absolute"
          : "fixed";
      buttonRef.current.style.position = position;
    }
  }, []);

  useEffect(() => {
    if (isWindowDefined) {
      handleScroll();
      window?.addEventListener("scroll", handleScroll, { passive: true });
      window?.addEventListener("resize", handleScroll, { passive: true });

      return () => {
        window?.removeEventListener("scroll", handleScroll);
        window?.removeEventListener("resize", handleScroll);
      };
    }
  }, [handleScroll]);

  return { isVisible, buttonRef };
};

export const RHScrollUpButton = () => {
  const { isVisible, buttonRef } = useScrollVisibility();

  const scrollTop = useCallback(() => {
    if (!isWindowDefined) return;

    const duration = 1000;
    const start = window?.pageYOffset;
    const startTime =
      "now" in window?.performance ? performance?.now() : new Date().getTime();

    const easeInOutCubic = (t: number): number => {
      return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
    };

    const scroll = () => {
      const currentTime =
        "now" in window?.performance
          ? performance?.now()
          : new Date().getTime();
      const time = Math.min(1, (currentTime - startTime) / duration);
      const easedTime = easeInOutCubic(time);

      window?.scrollTo(0, start * (1 - easedTime));

      if (time < 1) {
        requestAnimationFrame(scroll);
      }
    };

    requestAnimationFrame(scroll);
  }, []);

  return (
    <Fade in={isVisible}>
      <Fab
        ref={buttonRef}
        className="bottom-20 right-4 !bg-white !border !border-solid !border-[#999999] z-[11]"
        onClick={scrollTop}
      >
        <RHArrowIcon
          style={{ transform: "rotate(-90deg)" }}
          className="!text-[15px]"
        />
      </Fab>
    </Fade>
  );
};

export default RHScrollUpButton;
